'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const USER_ID_KEY = 'bw_user_id';

const SearchContext = createContext<any>(undefined);

export const SearchProvider = ({ children }) => {
  const [searchHistory, setSearchHistory] = useState([]);
  const [frequentSearch, setFrequentSearch] = useState([]);

  useEffect(() => {
    const userId = localStorage.getItem(USER_ID_KEY);
    if (userId) {
      axios.get(`/api/search/user/${userId}`).then((res) => {
        setSearchHistory(
          res.data.map((d) => ({ name: d.search, history: true }))
        );
      });
    }
    if (process.env.COUNTRY_CODE !== 'us') {
      axios.get(`/api/search/frequent`).then((res) => {
        setFrequentSearch(
          res.data.map((d) => ({ name: d.search, ...d, frequent: true }))
        );
      });
    }
  }, []);

  return (
    <SearchContext.Provider value={{ searchHistory, frequentSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(SearchContext);
};
